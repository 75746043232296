import React from "react";
import "./sol2.css";

import softwareDevImage from "../Images/software-dev.jpg";
import digitalMarketingImage from "../Images/digital-marketing.jpg";
import ventureBuildingImage from "../Images/venture-building.jpg";

function Sol2() {
  return (
    <div className="sol2-container">
      {/* First Row */}
      <div className="sol2-grid-container">
        {/* First Image (Text on Top) */}
        <div className="sol2-project-box">
          <h3 className="sol2-text-top">SOFTWARE DEVELOPMENT</h3>
          <img src={softwareDevImage} alt="Software Development" />
        </div>

        {/* Second Image (Text at Bottom) */}
        <div className="sol2-project-box">
          <img src={digitalMarketingImage} alt="Digital Marketing" />
          <h3 className="sol2-text-bottom">DIGITAL MARKETING</h3>
        </div>
      </div>

      {/* Second Row (Wider Image) */}
      <div className="sol2-project-box sol2-wide">
        <h3 className="sol2-text-top">VENTURE BUILDING</h3>
        <img src={ventureBuildingImage} alt="Venture Building" />
      </div>
    </div>
  );
}

export default Sol2;
