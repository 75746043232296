import React from "react";
import "./Page7.css";
import teams from '../Images/teams.png';
import employee from '../Images/employee.png';
import investor from '../Images/investor.png';
const Page7 = () => {
  return (
    <div className="page7-container">
      <div className="page7-left">
        <h1 className="page7-title">
          WE CREATE <br />
          COMPANIES <br />
          WITH DRIVEN <br />
          PROFESSIONALS
        </h1>
        <button className="page7-button">CONTACT US</button>
      </div>

      <div className="page7-right">
        <div className="page7-box">
          <img src={teams} alt="Team" className="page7-icon" />
          <p>JOIN AS TEAM</p>
        </div>
        <div className="page7-box">
          <img src={investor} alt="Investor" className="page7-icon" />
          <p>JOIN AS INVESTOR</p>
        </div>
        <div className="page7-box">
          <img src={employee} alt="Founder" className="page7-icon" />
          <p>JOIN AS FOUNDER</p>
        </div>
      </div>
    </div>
  );
};

export default Page7;
