import React from "react";
import "./Model.css";

const Model = () => {
  return (
    <div className="model-container">
      <h1 className="model-title">OUR PROVEN AND FLEXIBLE MODEL</h1>
      <p className="model-subtitle">
        Leverage the expertise and insights of professionals in your field to
        guide decision-making and innovation.
      </p>

      {/* Wrapper to handle both semicircle and vertical line */}
      <div className="model-items-container">
        <div className="model-semicircle">
          <div className="model-item top">COLLECTIVE WISDOM</div>
          <div className="model-item left">INFORMED<br></br> DECISION-MAKING</div>
          <div className="model-item bottom-left">FUTURE TECH TRENDS</div>
          <div className="model-item right">EMBRACE RISK</div>
          <div className="model-item bottom-right">SCALABILITY<br></br> AND GROWTH</div>
        </div>
      </div>
    </div>
  );
};

export default Model;
