import React from 'react';
import '../Associates/who.css'; // Ensure the path is correct
import logo from '../Images/logo1.png'; // Ensure the path is correct

const Who = () => {
  return (
    <div className="who-container">
      <div className="who-text">
        <h1>THIS IS WHO  <br/> WE ARE.</h1>
      </div>
      <div className="logo-section">
        <img src={logo} alt="Logo" className="logo-image" />
      </div>
    </div>
  );
}

export default Who;
