import React from 'react';
import { TypeAnimation } from 'react-type-animation';
import './HomePage.css'; 
import heroImage from '../Images/1.jpg';
import companyLogo from '../Images/logo1.png';

function HomePage() {
  return (
    <div className="homepage-pro-container">
      {/* Hero Section */}
      <div className="homepage-pro-hero">
        <img src={heroImage} alt="Hero Background" className="homepage-pro-hero-img" />
        <div className="homepage-pro-overlay"></div>
        <div className="homepage-pro-content">
          <div className="homepage-pro-logo-container">
            <img src={companyLogo} alt="Company Logo" className="homepage-pro-logo" />
            <h2 className="homepage-pro-company-name">DUSKY TECH</h2>
          </div>
          <div className="homepage-pro-text">
            <TypeAnimation
              sequence={['GREAT PRODUCT', 1500, 'IS BUILT BY', 1500, 'GREAT TEAMS', 1500]}
              speed={50}
              repeat={Infinity}
              wrapper="h1"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
