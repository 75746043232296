import React from 'react';
import './join.css';
import team from '../Images/team.png';

function Join() {
  return (
    <div className="join-body-custom">
      <div className="join-container-custom">
        {/* Text Section */}
        <div className="text-section-custom">
          <h1>JOIN OUR TEAM</h1>
          <p>
            We are always looking for<br />
            <span className="highlight-text">passionate & talented individuals</span>
          </p>
          <div className="text-section-custom1">
            <p>Send your resume at:</p>
          </div>
          <a href="mailto:duskytechco@gmail.com" className="email-link">
            duskytechco@gmail.com
          </a>
        </div>

        {/* Image Section */}
        <div className="image-section-custom">
          <img src={team} alt="Team Collaboration" className="team-image" />
        </div>
      </div>
    </div>
  );
}

export default Join;
