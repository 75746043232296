import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './Pages/HomePage';
import CompanyProfilePage from './Pages/CompanyProfilePage';
import ProjectsPage from './Pages/ProjectsPage';
import ServicesPage from './Pages/ServicesPage';
// import Thought from './Pages/thought';
import Footer from './Pages/Footer';
import Page1 from './ProjectPage/Page1';
import Page2 from './ProjectPage/Page2';
import Who from './Associates/who';
import Why from './Associates/why';
// import US from './Associates/us';
import Sol1 from './Solutions/sol1';
import Sol2 from './Solutions/sol2';
import Contact1 from './Contact/contact1';
import Contact2 from './Contact/contact2';
import Navbar from './Navbar';
import Collab from './Associates/collab';
import Member from './Associates/member';
import Join from './Join/join';
import Model from './Pages/Model';
import Page7 from './Pages/Page7';
import Page6 from './Pages/Page6';

// Get the root element
const rootElement = document.getElementById('root');

// Create a root
const root = ReactDOM.createRoot(rootElement);

// Render the app using the new API
root.render(
  <React.StrictMode>
    <Router>
      <Navbar />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <HomePage />
              <CompanyProfilePage />
              <ServicesPage />
              <Model />
              <ProjectsPage />
              <Page6 />
              <Page7 />
              <Footer />
            </>
          }
        />
        <Route
          path="/projects"
          element={
            <>
              <Page1 />
              <Page2 />
              <Page7 />
              <Footer />
            </>
          }
        />
        <Route
          path="/associates"
          element={
            <>
              <Who />
              <Why />
              {/* <US/> */}
              <Collab />
              <Page7 />
              <Member />
              <Footer />
            </>
          }
        />
        <Route
          path="/solutions"
          element={
            <>
              <Sol1 />
              <Sol2 />
              <Page7 />
              <Footer />
            </>
          }
        />
        <Route
          path="/contact"
          element={
            <>
              <Contact1 />
              <Contact2 />
            </>
          }
        />
        <Route
          path="/join"
          element={
            <>
              <Join />
              <Footer />
            </>
          }
        />
      </Routes>
    </Router>
  </React.StrictMode>
);
