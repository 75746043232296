import React from 'react';
import '../ProjectPage/Page1.css'; // Ensure the path is correct

const Page1 = () => {
  React.useEffect(() => {
    document.body.classList.add('page1-body');
    return () => {
      document.body.classList.remove('page1-body');
    };
  }, []);

  return (
    <div className="page1-container">
      <div className="overlay">
        <h1>OUR PAST PROJECTS <br/>SPEAK FOR US</h1>
      </div>
    </div>
  );
}

export default Page1;
