import React from 'react';
import '../Solutions/sol1.css'; // Ensure the path is correct
// Replace with the correct path to your background image


const Sol1 = () => {
  return (
    <div className="background">
      <div className="centered-text">
        WHAT WE <br/>SPECIALIZE IN...
      </div>
    </div>
  );
}

export default Sol1;
