import React from "react";
import "./Page6.css";

const Page6 = () => {
  return (
    <div className="page6-container">
      {/* Background Shapes (Now Positioned Below Buttons) */}
      <div className="background-shape left"></div>
      <div className="background-shape right"></div>
      <div className="background-shape middle"></div>

      {/* Title */}
      <h2 className="page6-title">WE HAVE OUR EXPERTISE IN...</h2>

      {/* Buttons Grid */}
      <div className="page6-buttons">
        <button className="page6-btn">RESEARCH</button>
        <button className="page6-btn">LEADERSHIP</button>
        <button className="page6-btn">MARKETING & DESIGN</button>
        <button className="page6-btn">BUSINESS STRATEGY</button>
        <button className="page6-btn">CYBERSECURITY AND TECH</button>
        <button className="page6-btn">HR & TALENT</button>
      </div>
    </div>
  );
};

export default Page6;
