import React from 'react';
import '../Associates/why.css'; // Ensure the path is correct
import jer from '../Images/jer.png';

const Why = () => {
  return (
    <div className="why-page">
      <div className="why-left">
        <h1>WE ARE AS PASSIONATE ABOUT YOUR BUSINESS AS YOU ARE</h1>
        <p>
          Dusky is a hub of innovation and ambition, where driven individuals converge to spark
          industry-leading change. Our high-achieving team is united by a commitment to excellence
          and a passion for redefining industry standards. Joining Dusky means more than just a job;
          it’s an invitation to be part of a transformative experience in venture building. We’re
          looking for collaborators who challenge the status quo, embrace creative problem-solving,
          and have the vision to turn bold ideas into reality. At Dusky, you’re not just an employee
          but a catalyst for groundbreaking change and disruptive growth.
        </p>
      </div>
      <div className="why-right">
        <img src={jer} alt="Person with arms crossed" />
      </div>
    </div>
  );
};

export default Why;
