import React, { useEffect } from 'react';
import './contact1.css'; // Ensure this file path is correct

const Contact1 = () => {
  // Add a unique class to the body tag when the component mounts
  useEffect(() => {
    document.body.classList.add('contact1-body');

    // Cleanup: Remove the class when the component unmounts
    return () => {
      document.body.classList.remove('contact1-body');
    };
  }, []);

  return (
    <div className="contact1-background">
      <div className="contact1-centered-text">
        GOT A QUESTION<span className="falling-question">?</span>
        <br />
        <span className="fade-in-text">REACH OUT</span>
      </div>
    </div>
  );
};

export default Contact1;