import React, { useState } from "react";
import "./Page2.css";
import rethinkPackLogo from "../Images/rethink-pack-logo.png";
import crmImage from "../Images/crm-image.jpg";
import cyberSecurityImage from "../Images/cyber-security-image.jpg";
import stylizedPLogo from "../Images/stylized-p-logo.png";
import solution from "../Images/solution.png";
import security from "../Images/security.png";
import motosing from "../Images/motosing.png";
import rethink from "../Images/rethink.png";
const projects = [
  {
    id: 1,
    logo: motosing,
    founded: "2023",
    category: "Software Development",
    image: crmImage,
    description: "A robust CRM system designed to streamline sales operations. By automating repetitive tasks and optimizing sales workflows, it empowers teams to focus on strategic initiatives and build stronger customer relationships."
  },
  
  {
    id: 3,
    logo: rethink,
    founded: "2024",
    category: "IT Support",
    image: rethinkPackLogo,
    description: "24/7 IT support and infrastructure management.We are a young startup driven by a passion to combat packaging pollution by revolutionary shift packaging practices through holistic sustainable & circular economy design and redesign waste out from the system. Our mission is to analyze and provide solutions for any challenges that may arise in the packaging design process"
  },
  {
    id: 4,
    logo: stylizedPLogo,
    founded: "2020",
    category: "Customer Relations",
    image: stylizedPLogo,
    description: "Implemented security protocols and risk assessments.Swim Preceptors is a group of Gen Zers committed to innovative teaching methods, aiming to bring out the full potential of each preceptee, introducing a fresher syllabus on swimming, the #SwimPreceptorsWay."

  },
  {
    id: 5,
    logo: security,
    founded: "2024",
    category: "Custom Integration",
    image: security,
    description: "Recognizing the critical role of cyber security in early-stage startups, we offer a centralized, robust security infrastructure. Unlike traditional models that prioritize development over protection, we empower businesses to focus on growth by providing a fortified digital environment which is free. Our comprehensive approach, encompassing both software and physical security measures, these efforts are imposed to encourage company independence."
  },
  {
    id: 6,
    logo: solution,
    founded: "2023",
    category: "Solution Design",
    image: solution,
    description: "A user-friendly customer support panel with AI-powered chat functionality. By integrating WhatsApp, we provide an accessible platform for customers to receive initial assistance from an AI chatbot before being seamlessly transferred to a human agent when necessary."
  }
];

function Page2() {
  const [selectedProject, setSelectedProject] = useState(null);

  return (
    <div className="page2-container">
      <h2>
        We prioritize your profit. We invest in your success before our own. Our comprehensive approach encompasses planning, building, and managing your business to maximize your runway and minimize risk.
      </h2>
      <div className="grid-container">
        {projects.map((project) => (
          <div
            key={project.id}
            className="project-box"
            onClick={() => setSelectedProject(project)}
          >
            <img src={project.image} alt="Project" />
          </div>
        ))}
      </div>

      {selectedProject && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-btn" onClick={() => setSelectedProject(null)}>
              &times;
            </button>
            <div className="popup-layout">
              <div className="popup-sidebar">
                <div className="popup-box ">{selectedProject.logo && <img src={selectedProject.logo} alt="Company Logo" />}</div>
                <div className="popup-box middle">Founded: {selectedProject.founded}</div>
                <div className="popup-box bottom">Category: {selectedProject.category}</div>
              </div>
              <div className="popup-main">
                <div className="popup-content-wrapper">
                  <div className="popup-image">
                    <img src={selectedProject.image} alt="Project Image" />
                  </div>
                  <div className="popup-description">
                    {selectedProject.description}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Page2;
