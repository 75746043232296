import React from 'react';
import '../Pages/CompanyProfilePage.css'; // Ensure the path is correct
import image1 from '../Images/2.jpg'; // Ensure the path is correct

const CompanyProfilePage = () => {
  return (
    <div className="company-profile-page">
      <div className="background-image">
        <div className="content">
          <h1>COMPANY PROFILE</h1>
          <p>
            Our venture-building studio draws on years of software agency expertise <br/>to empower tech startups with the resources they need to succeed.
          <br/>
          <a href="/Associates" className="read-more-link">READ MORE</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default CompanyProfilePage;
