import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../Pages/ProjectsPage.css';
import rethinkPackLogo from '../Images/rethink-pack-logo.png';
import crmImage from '../Images/crm-image.jpg';
import cyberSecurityImage from '../Images/cyber-security-image.jpg';
import stylizedPLogo from '../Images/stylized-p-logo.png';

const ProjectsPage = () => {
  const navigate = useNavigate();

  const handleExploreClick = () => {
    navigate('/projects');
  };

  const handleContactClick = () => {
    const footer = document.getElementById('footer');
    if (footer) {
      footer.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="projects-page">
      <div className="left-section">
        <div className="image-container1">
          <img src={rethinkPackLogo} alt="ReThinkPack Logo" />
        </div>
        <div className="image-container">
          <img src={crmImage} alt="CRM Customer Relationship Management" />
        </div>
        <div className="image-container">
          <img src={cyberSecurityImage} alt="Cyber Security" />
        </div>
        <div className="image-container">
          <img src={stylizedPLogo} alt="Stylized P Logo" />
        </div>
      </div>
      <div className="right-section">
        <h1>OUR PAST WORK</h1>
        <p>
          We prioritize your profit. We invest in your success before our own. 
          Our comprehensive approach encompasses planning, building, and managing 
          your business to maximize your runway and minimize risk. 
          <a href="/projects" className="read-more-link">READ MORE</a>
        </p>
        <div className="button-container">
          <button className="explore-btn" onClick={handleExploreClick}>Explore</button>
          <button className="contact-btn" onClick={handleContactClick}>Contact Us</button>
        </div>
      </div>
    </div>
  );
}

export default ProjectsPage;
