import React from 'react';
import '../Pages/ServicesPage.css';
import fundingIcon from '../Images/funding-icon.png';
import researchIcon from '../Images/research-icon.png';
import strategyIcon from '../Images/strategy-icon.png';
import marketingIcon from '../Images/funding-icon.png';
import itSupportIcon from '../Images/research-icon.png';
import ventureIcon from '../Images/strategy-icon.png';
import seoIcon from '../Images/funding-icon.png';

function ServicesPage() {
  const services = [
    {
      icon: fundingIcon,
      title: 'Funding',
      description: 'We prepare everything you need for your tax return.',
    },
    {
      icon: researchIcon,
      title: 'Market Research',
      description: 'We build financial models you can pitch to investors.',
    },
    {
      icon: strategyIcon,
      title: 'Business Strategy',
      description: 'We deliver thoughtful investment advisory services.',
    },
    {
      icon: marketingIcon,
      title: 'Marketing',
      description:
        'We craft targeted marketing campaigns to elevate your brand.',
    },
    {
      icon: itSupportIcon,
      title: 'IT Support',
      description:
        'We provide reliable IT support to keep your operations running smoothly.',
    },
    {
      icon: ventureIcon,
      title: 'Venture Building',
      description:
        'We partner with entrepreneurs to turn innovative ideas into thriving businesses.',
    },
    {
      icon: seoIcon,
      title: 'SEO',
      description:
        'We optimize your online presence to improve visibility and drive traffic.',
    },
  ];

  return (
    <div className="services-container">
      <h2 className="services-title">OUR SERVICES</h2>
      <div className="services-grid">
        {services.map((service, index) => (
          <div key={index} className="service-item">
            <img src={service.icon} alt={service.title} className="service-icon" />
            <h3 className="service-heading">{service.title}</h3>
            <p className="service-description">{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ServicesPage;
