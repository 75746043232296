import React from 'react';
import '../Pages/Footer.css'; // Ensure the path is correct

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-content">
        <div className="footer-text">
          <h1>LET'S WORK <br/>TOGETHER.</h1>
        </div>
        <div className="footer-contact">
          {/* <p>Mailing Address<br /><strong>123 Anywhere St. Any City, ST 12345</strong></p> */}
          <p>Email Address<br /><a href="mailto:duskytechco@gmail.com"><strong>duskytechco@gmail.com</strong></a></p>
          <p>Phone Number<br /> <strong>(65) 86510121</strong></p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
