import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logo from './Images/logo.png'; // Update with your logo path

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMailClick = () => {
    window.open('mailto:duskytechco@gmail.com', '_blank');
  };

  const closeMenuOnClick = () => {
    // Close the menu after selecting an option
    if (window.innerWidth <= 768) {
      setIsMenuOpen(false);
    }
  };

  return (
    <>
      <nav className={`navbar ${isMenuOpen ? 'menu-open' : ''}`}>
        <div className="navbar-left">
          <img src={logo} alt="Logo" className="navbar-logo" />
        
        </div>
        <ul className={`navbar-links ${isMenuOpen ? 'active' : ''}`}>
          <li><Link to="/" onClick={closeMenuOnClick}>Home</Link></li>
          <li><Link to="/projects" onClick={closeMenuOnClick}>Projects</Link></li>
          <li><Link to="/associates" onClick={closeMenuOnClick}>Associates</Link></li>
          <li><Link to="/contact" onClick={closeMenuOnClick}>Contact Us</Link></li>
          <li><Link to="/solutions" onClick={closeMenuOnClick}>Solutions</Link></li>
          <li><Link to="/join" onClick={closeMenuOnClick}>Join Us</Link></li>
        </ul>
        <button className="navbar-button" onClick={handleMailClick}>
          Mail Us
        </button>
        <div className={`hamburger ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </nav>
      <div className={`navbar-links-background ${isMenuOpen ? 'active' : ''}`} onClick={toggleMenu}></div>
    </>
  );
}

export default Navbar;
