import React from 'react';
import './collab.css'; // Ensure the path is correct

// Importing logos
import malaya from '../Images/university-malaya.png';
import indonesia from '../Images/university-indonesia.png';
import iit from '../Images/iit.png';
import klu from '../Images/klu.png';
import sunway from '../Images/sunway-university.png';
import apiit from '../Images/apiit-education-group.png';
import ucsi from '../Images/ucsi-university.png';
import scalacode from '../Images/scalacode.png';
import beno from '../Images/benosupport.png';
import flykez from '../Images/flykez.png';
import seven from '../Images/776.png';
import identifymakers from '../Images/identitymakers.png';
import sisa from '../Images/sisa.png';

const Collab = () => {
  return (
    <div className="collab-page">
      {/* Top Heading */}
      <h1>OUR EMPLOYEES ARE <br></br>FROM SOME OF THE BEST<br></br> PLACES</h1>

      {/* University Logos Section */}
      <div className="collab-logos employees-section">
        <div className="collab-logo"><img src={malaya} alt="Universiti Malaya" /></div>
        <div className="collab-logo"><img src={indonesia} alt="Universitas Indonesia" /></div>
        <div className="collab-logo"><img src={iit} alt="IIT Palakkad" /></div>
        <div className="collab-logo"><img src={sunway} alt="Sunway University" /></div>
        <div className="collab-logo"><img src={apiit} alt="A.P.I.I.T Education Group" /></div>
        <div className="collab-logo"><img src={ucsi} alt="UCSI University" /></div>
        <div className="collab-logo"><img src={klu} alt="KL University" /></div>
      </div>

      {/* Software Development Heading */}
      <h2>SOFTWARE<br></br> DEVELOPMENT</h2>

      {/* Company Logos Section */}
      <div className="collab-logos software-section">
        <div className="collab-logo"><img src={scalacode} alt="Scalacode" /></div>
        <div className="collab-logo"><img src={beno} alt="Beno Support" /></div>
        <div className="collab-logo"><img src={seven} alt="776" /></div>
        <div className="collab-logo"><img src={flykez} alt="Flykez" /></div>
        <div className="collab-logo"><img src={identifymakers} alt="Identity Makers" /></div>
        <div className="collab-logo"><img src={sisa} alt="SISA" /></div>
      </div>
    </div>
  );
};

export default Collab;
