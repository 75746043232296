import React from "react";
import "../Contact/contact2.css";

const Contact2 = () => {
  return (
    <div className="contact2-container">
      <div className="contact2-left">
        <h1>WE'RE HERE TO HELP!</h1>
        <p>
          PLEASE SEND US YOUR QUERY VIA THE FORM BELOW OR SEND US AN<br/> EMAIL AT
          <br />
          <strong>duskytechco@gmail.com</strong>
          <br />
          OR ANY ISSUE YOU'RE FACING
        </p>
      </div>
      <div className="contact2-right">
        <form>
          <label htmlFor="name">NAME</label>
          <input type="text" id="name" name="name" />

          <label htmlFor="phone">PHONE NUMBER</label>
          <input type="tel" id="phone" name="phone" />

          <label htmlFor="email">EMAIL</label>
          <input type="email" id="email" name="email" />

          <label htmlFor="message">MESSAGE</label>
          <textarea id="message" name="message"></textarea>
        </form>
      </div>
    </div>
  );
};

export default Contact2;
