import React from 'react';
import './member.css'; // Ensure the path is correct
import a from '../Images/a.png';
import ace from '../Images/ace.png';
import asia from '../Images/asia.png';

const Member = () => {
  return (
    <div className="member-page">
      <h1>WE ARE ALSO MEMBERS <br></br>OF...</h1>
      <div className="member-logos">
        <div className="member-logo"><img src={a} alt="Logo 1" /></div>
        <div className="member-logo"><img src={asia} alt="Logo 2" /></div>
        <div className="member-logo"><img src={ace} alt="Logo 3" /></div>
      </div>
    </div>
  );
};

export default Member;
